import Spark_Analytics from './analytics';
import Spark_API from './api';
import Spark_Campaigns from './campaigns';
import Spark_Chats from './chats';
import Spark_Checkins from './checkins';
import Spark_Cookies from './cookies';
import Spark_Defaults from './defaults';
import Spark_Externals from './externals';
import Spark_Helpers from './helpers';
import Spark_Location from './location';
import Spark_Messages from './messaging';
import Spark_Queue from './queue';
import Spark_Holidays from './holidays';
import { App } from '@capacitor/app';
import { clearAll, setStoreItem, listItems } from '../storage/local';
import { Capacitor } from '@capacitor/core';
import { PrivacyScreen } from 'mm-capacitor-privacy-screen';

import { globalVariables } from '../global-vars';

class Spark {
    constructor(store) {
        this.store = store;
        this.gv = globalVariables;
        this._backEvents = [];

        this.Analytics = new Spark_Analytics(this);
        this.API = new Spark_API(this);
        this.Campaigns = new Spark_Campaigns(this);
        this.Chats = new Spark_Chats(this);
        this.Checkins = new Spark_Checkins(this);
        this.Cookies = new Spark_Cookies(this);
        this.Defaults = new Spark_Defaults(this);
        this.Externals = new Spark_Externals(this);
        this.Helpers = new Spark_Helpers(this);
        this.Location = new Spark_Location(this);
        this.Messages = new Spark_Messages(this);
        this.Queue = new Spark_Queue(this);
        this.Holidays = new Spark_Holidays(this);
    }

    install(app) {
        app.config.globalProperties.$spark = this;
    }

    compareVersions(current, newer) {
        const currentVersion = (current ?? '0.0.0').split('.').map((c) => {
            return parseInt(c);
        });

        const newVersion = (newer ?? '0.0.0').split('.').map((c) => {
            return parseInt(c);
        });

        if (newVersion[0] > currentVersion[0]) {
            //major is old
            return 'major';
        } else if (currentVersion[0] > newVersion[0]) {
            return 'ok'; //current is newer than req
        }

        if (newVersion[1] > currentVersion[1]) {
            //minor is old
            return 'minor';
        } else if (currentVersion[1] > newVersion[1]) {
            return 'ok'; //current is newer than req
        }

        if (newVersion[2] > currentVersion[2]) {
            return 'patch';
        }
        return 'ok';
    }

    //RULES
    //Major different = app update needed
    //Minor different = app update needed
    //Patch different = self update needed (TODO: for now, app update required)
    async verifyVersion() {
        const currentVersion = this.gv.version ?? '0.0.0';

        //check platform specific version, otherwise fallback to global min version
        let m_version =
            this.store.getters['config/configurationValue'](`minimum_${this.platform()}_version`) ||
            this.store.getters['config/configurationValue']('minimum_version') ||
            '0.0.0';

        let outcome = this.compareVersions(currentVersion, m_version);

        //if iOS, verify that the new version is actually available. so we don't hard block the user
        if (this.platform() == 'ios' && outcome != 'ok') {
            const { version: app_store_version } = await this.checkAvailableiOSVersion();
            if (app_store_version) {
                outcome = this.compareVersions(currentVersion, app_store_version);
                if (outcome == 'ok') {
                    m_version = app_store_version;
                }
            }
        }

        return {
            version_state: outcome,
            min_version: m_version,
        };
    }

    async checkAvailableiOSVersion() {
        const { Versioning } = await import(
            /* webpackChunkName: "ios-versioning" */ 'mm-capacitor-ios-versioning'
        );
        return await Versioning.findCurrentVersion();
    }

    checkGlobalPrivacyScreen() {
        const block = this.store.getters['config/configurationValue']('block_screenshots') || false;
        console.log('privacy screen should be ' + (block ? 'on' : 'off'));
        if (block) {
            this.enablePrivacyScreen();
        } else {
            this.disablePrivacyScreen();
        }
    }

    enablePrivacyScreen() {
        if (this.platform() == 'web') {
            return; //no affect on web
        }

        PrivacyScreen.enable();
    }

    disablePrivacyScreen() {
        if (this.platform() == 'web') {
            return; //no affect on web
        }

        PrivacyScreen.disable();
    }

    ensureAuth() {
        if (this.store.state.config.configuration.api) {
            this.API.setAPIURL(
                this.store.state.config.configuration.id,
                this.store.state.config.configuration.api,
                this.store.state.config.configuration.assets,
                this.store.state.config.configuration.tag
            );
        }

        const valid = this.store.getters['profile/getTokenValidity'];
        if (valid) {
            this.startSecureWatch();
            this.Queue.start();
        }

        return valid;
    }

    startSecureWatch() {
        this.watchingDaily();
        this.watchingVisibility();
    }

    stopSecureWatch() {
        clearInterval(this._sameDayLoop);
        this._sameDayLoop = null;

        this._visibilityListener = null;

        this.logout();
    }

    watchingDaily() {
        if (this._sameDayLoop) {
            return;
        }
        this._sameDayLoop = setInterval(() => {
            if (!this.ensureAuth()) {
                this.stopSecureWatch();
            }
        }, 30 * 60000); //every 30m, verify if still same day
    }

    watchingVisibility() {
        if (this._visibilityListener) {
            return;
        }

        this._visibilityListener = (state) => {
            if (state?.isActive) {
                if (!this.ensureAuth()) {
                    this.stopSecureWatch();
                } else {
                    //verify if Indexed DB is still working
                    try {
                        listItems();
                    } catch (e) {
                        //this should never happen in normal usage...
                        this.Analytics.Exception('indexed-db-error', e);
                    }
                }
            }
        };

        App.addListener('appStateChange', this._visibilityListener);
    }

    addBackListener(event) {
        this._backEvents.push(event);
    }
    removeBackListener(callback) {
        for (let j = 0; j < this._backEvents.length; j++) {
            if (this._backEvents[j].callback == callback) {
                this._backEvents.splice(j, 1);
                break;
            }
        }
    }
    triggerBackListeners() {
        if (this._backEvents.length) {
            this._backEvents.forEach((c) => {
                if (c.parameters) {
                    c.callback.call(c.context, ...c.parameters);
                } else {
                    c.callback.call(c.context);
                }
            });
            return true;
        }

        return false;
    }

    platform() {
        if (this._platform) {
            return this._platform;
        }

        this._platform = Capacitor.getPlatform();
        return this._platform;
    }

    async checkLowEndDevice() {
        let isLowEnd = this.store.state.config.isLowEndDevice;
        if (isLowEnd !== -1) {
            return isLowEnd;
        }

        const info = await this.Helpers.getRAMInfo();
        const androidCutoff = 2 * 1073741824; //2GB
        const iosCutoff = 1.5 * 1073741824; //1.5GB
        //ios devices tend to run smoother at lower RAM amounts

        if (this.platform() == 'ios') {
            isLowEnd = info.total < iosCutoff;
        } else if (this.platform() == 'android') {
            isLowEnd = info.total < androidCutoff;
        } else if (info.total != info.inuse) {
            isLowEnd = info.total < androidCutoff;
        } else {
            isLowEnd = true;
        }

        this.store.commit('config/setLowEndDevice', isLowEnd);

        return isLowEnd;
    }

    async logout(skipReload) {
        this.API.logout();

        const checkins = this.store.state.profile.checkins;
        for (let j in checkins) {
            await this.Checkins.delete(j, 'checked-out');
        }

        await this.store.dispatch('config/undoLanguage');

        const configState = this.store.getters['config/getState']; //save the things we need to keep
        await clearAll(); //clear everything else...
        await setStoreItem('config', configState); //re-write the stuff we need to keep

        if (!skipReload) {
            setTimeout(() => {
                window.location.reload();
            }, 250);
        }
    }
}

export function setupSpark(store) {
    const spark = new Spark(store);
    return spark;
}
